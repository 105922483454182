.manageRentalsDropdown {
  position: relative;
  --button-padding: 0 var(--space-3x) 0;
  --button-font-size: var(--font-size-body);
  --button-text-color-hover: var(--color-indigo-300);
}

.manageRentalsDropdownIcon {
  font-size: var(--font-size-sm);
}

.manageRentalsDropdownItems {
  position: absolute;
  top: 100%;
  left: var(--space-3x);
  background-color: var(--color-gray-700);
  min-width: 187px;
  border: 1px solid var(--color-gray-600);
  border-radius: var(--border-radius-2x);
  padding: var(--space-1x);
  display: flex;
  flex-direction: column;
  animation: dropdown-enter 100ms ease-out;

  &.hidden {
    display: none;
  }
}

.manageRentalsDropdownItem {
  text-decoration: none;
  color: var(--color-white);
  font-weight: var(--font-weight-normal);
  text-align: left;
  line-height: var(--leading-normal);
  width: 100%;
  padding: var(--space-2-5x) var(--space-2x);
  font-size: var(--font-size-body);
}

.navSubItemLabel {
  font-weight: var(--font-weight-semibold);
}

.manageRentalsDropdownItem:hover {
  color: var(--color-white);
  background-color: var(--color-gray-600);
  border-radius: var(--border-radius-2x);
}

.manageRentalsDropdownItemActive {
  background-color: var(--color-gray-600);
}

@keyframes dropdown-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
